import React from 'react';
import { FaPhone } from 'react-icons/fa';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import data from '../../../../data/ocd';

import styles from './ocd.module.scss';

const OCD = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498695385/ocSpectrum.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/q_70/v1498876678/clouds.jpg';
  const image2 = 'https://res.cloudinary.com/dewd4pral/image/upload/q_70/v1498876841/hiker.jpg';
  const ocdCycleImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1698968066/ocd-cycle.png';

  const phoneIcon = <FaPhone size={22} color="#FFF" />;

  const ActionCard = ({ lastStop }) => {
    return (
      <a href="tel:8014271054">
        <div className={styles.actionCard}>
          <div className={styles.cardIcon}>{phoneIcon}</div>
          {lastStop && (
            <p className={styles.actionLastStop}>
              This is your last stop. You&apos;ve finally found OCD treatment providers who
              get&nbsp;you!
            </p>
          )}
          <p className={styles.actionCopy}>Schedule your appointment today!</p>
          <p className={styles.actionPhone}>801-427-1054</p>
        </div>
      </a>
    );
  };

  return (
    <main>
      <PageHeader pageCategory="Specialties" pageName="OCD Treatment" headerImage={background} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Specialized. Experienced. Results.</h2>
        <h2 className={styles.headerSmall}>
          Why choose Mindset Family Therapy for your OCD Treatment?
        </h2>

        <ul>
          <li>
            <strong>We specialize in treating OCD</strong>
            <ul>
              <li className={styles.sub}>We are NOT general practitioners</li>
            </ul>
          </li>

          <li>
            <strong>30+ years of successfully treating OCD</strong>
            <ul>
              <li className={styles.sub}>We get results!</li>
            </ul>
          </li>

          <li>
            <strong>
              We are regular presenters at the International OCD Foundation conferences
            </strong>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <ul>
          <li>
            <strong>We provide OCD consultation and training for other psychotherapists</strong>
          </li>

          <li>
            <strong>We have been trained to treat OCD by renowned experts in the field</strong>
          </li>

          <li>
            <strong>
              We understand your pain based on our own personal experiences with OCD and OC related
              disorders in our lives
            </strong>
          </li>

          <li>
            <strong>
              We treat OCD with evidence-based treatment modalities and stay up to date with the
              latest research and best treatment for OCD:
            </strong>
            <ul>
              <li className={styles.sub}>
                {' '}
                <strong>ERP</strong> (exposure and response prevention). The behavior part of
                cognitive-behavioral therapy (CBT). ERP is the gold-standard treatment for OCD.
              </li>
              <li className={styles.sub}>
                <strong>ACT</strong> (acceptance and commitment therapy). ACT is an exposure-based
                model and has ERP built into it. ACT has been shown to be effective in promoting and
                success of ERP in individuals struggling with OCD.
              </li>
              <li className={styles.sub}>
                <strong>I-CBT</strong> (inference-based cognitive behavioral therapy). I-CBT
                obsessional doubt and underlying reasoning process (inferential confusion) that
                maintains obsessional doubt. Can be used in conjunction with ERP. Similar efficacy
                efficacy as ERP.
              </li>
            </ul>
          </li>

          <li>
            <strong>We also provide therapeutic, psychoeducational, and support groups</strong>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard lastStop />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>What to Expect in Treatment</h2>

        <ul>
          <li>
            <strong>
              Recognize OCD is a genetic predisposition, neurological condition, and a behavioral
              challenge. OCD is not your fault!
            </strong>
          </li>

          <li>
            <strong>Learn psychological flexibility skills to disrupt the OCD cycle:</strong>
            <ul>
              <li className={styles.sub}>
                Become more <strong>open</strong> to painful internal experiences. Learn skills to
                get unstuck from them and allow them to come and go without getting caught in the
                OCD trap.
              </li>
              <li className={styles.sub}>
                Enhance your <strong>awareness</strong> so you can connect with the here and now and
                recognize that you are aware of being aware without having to become absorbed in
                compulsive and avoidant behaviors to get rid of internal events (e.g., thoughts and
                feelings).
              </li>
              <li className={styles.sub}>
                Become <strong>actively engaged</strong> in what is worth (values) your focus, time,
                and energy and choose to do what matters most in your life.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>ACT informed ERP for OCD</h2>
        <p className={styles.copy}>
          Values-based exposures are based on what you value (what matters most to you). The
          exposures are so that you can start living the life you want and deserve.
        </p>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>Values-Based Exposures</h2>
        <ul>
          <li>
            <strong>Bodily Sensations</strong> – Learn to change your relationship with your
            sensations and emotions
          </li>

          <li>
            <strong>Imaginal Exposures</strong> – Prepare to start living life by imagining and
            visualizing doing what matters even though OCD may be present
          </li>

          <li>
            <strong>Real-Time or In-Vivo Exposures</strong> – Start doing the things that matter to
            you and be with those who matter in your life!
          </li>
        </ul>

        <p style={{ textAlign: 'center' }} className={styles.copy}>
          <strong>
            You can learn to be actively engaged in what matters most each and every day!
          </strong>
        </p>
      </div>

      <QuoteImage image={image1} maxWidth={440} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>We treat all common themes in OCD including: </h2>
        <div className={styles.themesContainer}>
          <div className={styles.col}>
            <ul>
              <li>Moral and Religious Scrupulosity</li>
              <li>Perfectionism</li>
              <li>Harm</li>
              <li>Sexual</li>
              <li>Relationship</li>
            </ul>
          </div>
          <div className={styles.col}>
            <ul>
              <li>Existential or Philosophical</li>
              <li>Pedophiliac</li>
              <li>Sexual Orientation</li>
              <li>Contamination</li>
              <li>Just Right</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <br />
        <h2 className={styles.headerSmall}>Common Obsessions and Examples</h2>
        <ul>
          <li>Religious (fears around having blasphemous thoughts)</li>
          <li>Contamination (fears around environmental contaminants)</li>
          <li>Losing control (fears around acting on impulses to harm others or oneself)</li>
          <li>Harm (fears around harming others when not careful enough)</li>
          <li>Sexual (fears around inappropriate sexual behavior with others)</li>
          <li>Superstitious or magical (excessive worry around objects and other situations)</li>
          <li>Health (excessive worry around physical well-being)</li>
          <li>Perfectionistic (questioning whether you have told the truth perfectly)</li>
          <li>Neutral (excessive awareness of your thought processes)</li>
        </ul>

        <br />
        <br />

        <h2 className={styles.headerSmall}>Types of Compulsions</h2>
        <ul>
          <li>Mental (private) compulsions (mentally reviewing past events)</li>
          <li>Behavioral (public) compulsions (shaking one’s head to “get rid” of a thought)</li>
          <li>Reassurance-seeking compulsions (these can be private or public)</li>
          <li>Avoidant behaviors (staying away from loved ones for fear of harming them)</li>
        </ul>

        <br />
        <br />

        <p className={styles.copy}>
          For more detailed examples of the most common obsessions and compulsions, visit this link:{' '}
          <a href=" https://iocdf.org/about-ocd" target="_blank" rel="noopener noreferrer">
            https://iocdf.org/about-ocd
          </a>
        </p>
      </div>

      <QuoteImage image={image2} maxWidth={550} quote={data.quote2} />

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>The OCD Cycle</h2>
        <p className={styles.copy}>
          When you get stuck in the OCD trap, you’ll notice the following events:
        </p>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={ocdCycleImage} alt="The OCD Cycle" />
        </div>

        <ul>
          <li>
            <strong>Triggers: </strong>Anything in your environment (people, places, pets, words,
            sounds, etc.) or internal experiences, such as thoughts or feelings, that bring about
            the urge to obsess and lead you to become stuck in the OCD trap.
          </li>
          <li>
            <strong>Obsessions: </strong>Repetitive, excessive “worry thoughts” that can lead you to
            experience other difficult internal events.
          </li>
          <li>
            <strong>Distress: </strong>Unpleasant events, such as feelings, bodily sensations, and
            urges, that can feel intolerable.
            <ul>
              <li className={styles.sub}>
                <strong>Feelings: </strong>Anxiety, uncertainty, guilt, shame, etc.
              </li>
              <li className={styles.sub}>
                <strong>Bodily sensations: </strong>Feelings manifested in your body. You may
                believe you cannot tolerate these sensations, which may lead you to the urge to do
                something about them.
              </li>
              <li className={styles.sub}>
                <strong>Urges: </strong>You feel like you need to fix the situation (e.g., scratch
                an itch), since the internal experiences have become overwhelming.
              </li>
            </ul>
          </li>

          <li>
            <strong>Compulsions: </strong>Unhelpful safety behaviors you engage in to temporarily
            alleviate the discomfort triggered by a situation. These behaviors can be{' '}
            <strong>public</strong> (things others see you doing, such as repetitively asking for
            reassurance from a loved one or reading and rereading information on the internet) or{' '}
            <strong>private</strong> (things you do internally and privately, such as figuring
            things out in your head, fixating on past actions, or trying to predict the future). You
            may also find yourself engaging in excessive avoidant behaviors to find relief from your
            distress.
          </li>

          <li>
            <strong>Relief: </strong>Because you may have found seconds, minutes, or perhaps even
            hours of relief, your anxious mind leads you to believe that if you do these behaviors
            more intensely or longer, you might eventually find permanent relief. This short-term
            relief keeps you stuck in the cycle when you get triggered again.
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>OCD Scales and Questionnaires for Assessment</h2>
        <ul>
          <li>PIOS - Penn Inventory of Scrupulosity</li>
          <li>Y-BOCS - Yale Brown Obsessive-Compulsive Scale</li>
          <li>Obsessive Concern Checklist and Compulsive Activities Checklist</li>
          <li>Acceptance &amp; Action Questionnaire II for Obsessions &amp; Compulsions</li>
        </ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>References</h2>
        <p className={styles.references}>
          Twohig, M. P., Abramowitz, J. S., Bluett, E. J., Fabricant, L. E., Jacoby, R. J.,
          Morrison, K. L., Smith, B. M. (2015). Exposure therapy for OCD from an acceptance and
          commitment therapy (ACT) framework.
          <i> Journal of Obsessive-compulsive and Related Disorders</i>, 6, 167–173,{' '}
          <a
            href="http://dx.doi.org/10.1016/j.jocrd.2014.12.007"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://dx.doi.org/10.1016/j.jocrd.2014.12.007
          </a>
          <br />
          <br />
          Twohig MP, Abramowitz JS, Smith BM, et al. Adding acceptance and commitment therapy to
          exposure and response prevention for obsessive-compulsive disorder: A randomized
          controlled trial. <i>Behav Res Ther.</i> 2018;108:1-9. doi:10.1016/j.brat.2018.06.005,{' '}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/29966992/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://pubmed.ncbi.nlm.nih.gov/29966992/
          </a>
          <br />
          <br />
          Twohig, M. P., Abramowitz, J. S., Smith, B.M., Fabricant, L. E., Jacoby, R. J., Morrison
          K. L., Bluett, E. J., Reuman, L., Blake, S. M. , Ledermann, T. (2018). Adding acceptance
          and commitment therapy to exposure and response prevention for obsessive-compulsive
          disorder: A randomized controlled trial. <i>Behaviour Research and Therapy</i>, 108, 1-9,{' '}
          <a
            href="http://jonabram.web.unc.edu/files/2018/08/Twohig-et-al-2018-ACT-ERP.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://jonabram.web.unc.edu/files/2018/08/Twohig-et-al-2018-ACT-ERP.pdf
          </a>
        </p>
      </div>
    </main>
  );
};

export default OCD;

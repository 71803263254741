import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import OCD from '../../components/Specialties/components/OCD';

export default () => (
  <React.Fragment>
    <SEO
      title="OCD Treatment Specialists in Provo, UT | Mindset Family Therapy"
      description="Do you have OCD? We know OCD intimately and will help you find relief. Research indicates that one in 40 adults and one in 200 children suffer from Obsessive Compulsive Disorder. There are two conditions in the OCD experience: the obsessions (thoughts the individual has) and the compulsions (what the person does in order to decrease the anxiety he or she is experiencing)."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_70/v1498876678/clouds.jpg"
      pathname="/specialties/obsessive-compulsive-disorder"
      article
    />
    <Layout>
      <OCD />
    </Layout>
  </React.Fragment>
);

const ocd = {
  quote1:
    '<p>"Even if you’re on the right track, you’ll get run over if you just sit there.”</p><p>WILL ROGERS</p>',
  quote2:
    '<p>“Courage is the first of human qualities because it is the quality which guarantees all others.”</p><p>WINSTON CHURCHILL</p>',
  intro:
    'Our Clinical Director, Annabella Hagen, has a son who struggled for years to find adequate help for his OCD challenges. Eventually, he found the right treatment, but only at an expensive and out-of-state intensive treatment center. She saw firsthand the need for expert training to truly understand the complexities of OCD and really treat it. His struggle was the inspiration and impetus for making available a specialized care that’s local and affordable.<br/><br/>For the last five years, she and some of her staff have been presenters at the annual International OCD Foundation conference. They are humbled by the opportunity to share their ideas and insights with the global OCD community about best practices in helping those who struggle..<br/><br/>Continue reading below for more info on therapy. We look forward to helping you get back to what matters the most in your life.',
  intro2:
    'Treatment at our clinic is a “being in the present and doing” type of therapy. You will learn that by doing the home assignments, you will strengthen the skills that will serve you in other areas of your life as well.',
  overview:
    'There are two conditions in the OCD experience: the obsessions (thoughts the individual has) and the' +
    ' compulsions (what the person does in order to decrease the anxiety he or she is experiencing).<br/><br/>Misunderstandings regarding OCD exist regarding compulsions. For example, some people believe that OCD only involves external compulsions such as washing, cleaning, checking, reorganizing, etc. Repetitive rituals such as rereading or rewriting and counting are also types of OCD that most people recognize.<br/><br/>There are some individuals who experience obsessions and in order to neutralize the initial disturbing thought, they have additional thoughts to help them feel better. Some of the feelings they may experience are guilt, uncertainty, anxiety and many more, depending on the type of OCD they have. Though the individual may have additional thoughts to feel better they are still compulsions as they are being carried out to relieve the feelings.<br/><br/>Research indicates that one in 40 adults and one in 200 children suffer from Obsessive Compulsive Disorder. This is according to the reported and correctly diagnosed cases. Unfortunately, there are still many children, adolescents and adults suffering in silence or being misdiagnosed.',
  details:
    'The Yale Brown Obsessive Compulsive Scale (Y-BOCS) is the widely preferred tool to measure the severity of OCD' +
    ' symptoms. You will also have the opportunity to complete the Obsessive Concerns Check List and the Compulsive' +
    ' Activities Checklist. These two lists will provide us with a thorough outlook regarding your obsessions and compulsions so we can map out your treatment and help you understand how treatment will help you.',
  whatToExpect:
    'Treatment is mapped out individually, according to where you are and what is most needed. We use an' +
    ' integrative model to help our clients disentangle the complex web that is OCD.<br/><br/>We support the proven efficacy of Cognitive Behavioral Therapy (CBT), alongside Exposure and Response Prevention (ERP).<br/><br/>In addition to implementing CBT and ERP skills, we tie it all together with powerful ACT (Acceptance and Commitment Therapy) skills. ACT has been shown to make a significant difference in treatment when clients begin exposures, making it more doable and sustainable.* You will learn skills that help you notice when you get ‘hooked’ by your OCD thoughts, and learn how you can separate yourself from them.<br/><br/>We also provide mindfulness training so you can learn to observe your internal experiences and notice them for what they are — thoughts, feelings, sensations, and urges.<br/><br/>' +
    'Together, you and your therapist will identify external and internal triggers and the related' +
    ' compulsions.<br/><br/>You will learn to do imaginary and in-vivo exposures and acquire non-avoidance coping' +
    ' skills. Together we will develop a response prevention plan for you to follow along with our' +
    ' guidance.<br/><br/>You will learn that OCD does not have to rule your life, and that you can refocus on what' +
    ' matters most to you.<br/><br />Treatment at our clinic is a “being in the present and doing” type of therapy. You will learn that by doing the home assignments, you will strengthen the skills that will serve you in other areas of your life as well.',
  symptoms: [
    {
      name: 'Trigger',
      definition:
        'It can be a thought, image, situation, place, event, animal, or just about anything that causes individuals to start obsessing about their fears.',
    },
    {
      name: 'Obsessions',
      definition:
        'These are intrusive thoughts that won’t leave the person’s mind. One thought will lead to another and another. OCD sufferers find it difficult to redirect their attention away from these thoughts.',
    },
    {
      name: 'Feelings, Sensations and Urges',
      definition:
        'Feelings are intense and will vary according to the person’s\n' +
        'target obsession. Most people will experience anxiety and uncertainty. Guilt, depression, anger,\n' +
        'frustration, and other feelings may ensue. Those feelings in turn create unpleasant bodily\n' +
        'sensations that lead them to want to do something (urge to do the behavior) to rid themselves of\n' +
        'the unpleasant thoughts, feelings, and sensations.',
    },
    {
      name: 'Compulsions',
      definition:
        'Compulsions are whatever the person will do to find relief from the obsessions and feelings. The compulsions' +
        ' can be either behavioral or mental. Sometimes when individuals don’t receive treatment soon enough, their compulsions may become as automatic as their obsessions.',
    },
    {
      name: 'Relief',
      definition:
        'Relief is obtained by doing the compulsions and is what every OCD sufferer wishes for. Unfortunately, it will' +
        ' only be temporary until the next trigger appears. Unbeknownst to the individual, the false sense of improvement and relief is actually reinforcing the OCD cycle.',
    },
  ],
};

export default ocd;
